<template>
	<!-- eslint-disable -->
	<div>
		<div class="mb-5 md:flex md:items-center md:justify-between">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					Badges
				</h2>
			</div>
		</div>

		<div class="w-full">
			<div class="flex gap-x-10">
				<div class="flex-1">
					<FieldsInput
						v-model="form.name"
						:field="{
							type: 'input',
							inputType: 'text',
							label: 'Badge Name',
							placeholder: 'Badge Name',
							model: 'condition',
							required: true,
							colSpan: 'col-span-1',
						}"
					/>
					<div class="pb-2 sm:pt-3">
						<label for="page" class="block pb-1 text-sm font-bold text-gray-700">
							Page
						</label>

						<div class="relative mt-1 sm:mt-0">
							<Multiselect
								v-model="form.page_id"
								placeholder="Search for internal page"
								:filter-results="true"
								:min-chars="1"
								:resolve-on-load="false"
								:loading="!pageOptions"
								:disabled="!pageOptions"
								:delay="500"
								:searchable="true"
								:options="pageOptions"
							/>
							<IconButtonLoading :loading="!pageOptions" class="absolute right-0 text-black top-2" />
						</div>
					</div>

					<label for="media" class="block pb-1 text-sm font-bold text-gray-700">Badge Image</label>
					<media-library-attachment
						name="media"
						ref="mediaLibrary"
						:initial-value="form.media"
						:upload-domain="apiUrl"
						route-prefix="badge"
						:validation-rules="{
							accept: ['image/jpeg', 'image/png'],
							maxSizeInKB: 15 * 1024,
						}"
						:vapor-signed-storage-url="apiUrl + '/signed-storage-url'"
						vapor
						:with-credentials="true"
						@change="thisBadge => (form.media = thisBadge)"
					/>

					<div class="flex justify-end mt-5 gap-x-5">
						<button
							v-show="editing"
							type="button"
							class="relative inline-flex items-center px-4 py-2 font-medium text-white bg-gray-500 border border-transparent rounded-md shadow-sm hover:bg-gray-600 focus:outline-none"
							@click="cancelEdit"
						>
							<XIcon class="w-5 h-5 mr-2 text-white" aria-hidden="true" />
							Cancel
						</button>

						<button
							v-show="editing"
							type="button"
							class="relative inline-flex items-center px-4 py-2 font-medium text-white bg-green-500 border border-transparent rounded-md shadow-sm hover:bg-green-600 focus:outline-none"
							@click="update"
						>
							<SaveIcon class="w-5 h-5 mr-2 text-white" aria-hidden="true" />
							Save Badge
						</button>

						<button
							v-show="!editing"
							type="button"
							class="relative inline-flex items-center px-4 py-2 font-medium text-white bg-green-500 border border-transparent rounded-md shadow-sm hover:bg-green-600 focus:outline-none"
							@click="store"
						>
							<PlusCircleIcon class="w-5 h-5 mr-2 text-white" aria-hidden="true" />
							Add Badge
						</button>
					</div>
				</div>

				<div class="flex flex-col flex-1 gap-y-2">
					<div v-for="(element, index) in badges" :key="element.name" handle=".handle" tag="div" class="">
						<div class="flex items-center p-2 bg-white border rounded-md hover:bg-blue-50">
							<img :src="element.media.original_url" class="w-24 h-24" />
							<div class="flex-1 px-6 py-4 text-lg font-medium text-gray-900 whitespace-nowrap">
								{{ element.name }}
							</div>
							<div
								class="flex items-center px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap gap-x-8"
							>
								<!--<button
									type="button"
									class="relative inline-flex items-center p-1 font-medium text-red-600 bg-red-100 border border-transparent rounded-md shadow-sm hover:bg-red-200 focus:outline-none"
									@click="confirmDelete(element)"
								>
									<XIcon class="w-5 h-5" aria-hidden="true" />
								</button> -->
								<button
									type="button"
									class="relative inline-flex items-center p-1 font-medium text-yellow-600 bg-yellow-200 border border-transparent rounded-md shadow-sm hover:bg-yellow-300 focus:outline-none"
									@click="editBadge(index)"
								>
									<PencilIcon class="w-5 h-5" aria-hidden="true" />
								</button>
							</div>
						</div>
					</div>

					<div
						v-if="Object.keys(badges).length === 0"
						class="p-5 mt-8 text-lg font-bold text-yellow-600 bg-white border rounded-md"
					>
						No Badges
					</div>
				</div>
			</div>
		</div>

		<ModalsConfirmation
			ref="confirmation"
			:open="confirmationOpen"
			:confirmation-config="confirmationConfig"
			@closeModal="closeModal"
			@confirm="confirm"
		/>
	</div>
</template>

<script>
import { inject, reactive, ref } from 'vue'
import { useToast } from 'vue-toastification'
import { MediaLibraryAttachment } from '@spatie/media-library-pro-vue3-attachment'
import { PencilIcon, XIcon, PlusCircleIcon, SaveIcon } from '@heroicons/vue/solid'
import Multiselect from '@vueform/multiselect'
import getEnv from '@/extensions/env'

export default {
	name: 'Badges',
	components: {
		XIcon,
		PencilIcon,
		PlusCircleIcon,
		SaveIcon,
		MediaLibraryAttachment,
		Multiselect,
	},
	setup() {
		const apiUrl = ref(getEnv('VUE_APP_API_URL'))

		const api = inject('api')
		const toast = useToast()
		const form = reactive({
			media: null,
			name: '',
			url: '',
			page_id: null,
		})

		return {
			api,
			toast,
			form,
			apiUrl,
		}
	},
	data() {
		return {
			editing: false,
			edit: {
				name: null,
				badge: null,
				url: null,
				page_id: null,
			},
			confirmationOpen: false,
			confirmationConfig: {},
			badges: [],
			pageOptions: null,
		}
	},
	mounted() {
		//this.fetchAmenities()
		this.fetchBadges()
		this.fetchPages()
	},
	methods: {
		fetchBadges() {
			this.loaderShow()

			this.api.badges
				.all()
				.then(badges => {
					this.badges = badges
				})
				.catch(error => {
					this.toast.error('Error fetching badges:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		editBadge(index) {
			this.editing = true
			this.form = JSON.parse(JSON.stringify(this.badges[index]))
			console.log(this.form)
			this.form.media = null
			this.$refs.mediaLibrary.mediaLibrary.state.media = []
		},

		cancelEdit() {
			this.editing = false
			this.form = {
				name: null,
				page_id: null,
			}
		},

		store() {
			console.log('creating')
			this.loaderShow()

			this.api.badges
				.store(this.form)
				.then(badges => {
					this.cancelEdit()
					this.badges = badges
				})
				.catch(error => {
					this.toast.error('Error creating badges:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		update() {
			this.loaderShow()

			this.api.badges
				.update(this.form, this.form.id)
				.then(badges => {
					this.cancelEdit()
					this.badges = badges
					// Clear the media library state
					this.$refs.mediaLibrary.mediaLibrary.state.media = []
				})
				.catch(error => {
					this.toast.error('Error updating badges:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		closeModal() {
			this.confirmationOpen = false
			this.confirmationConfig = {}
			delete this.$refs.confirmation.id
		},

		confirm(callback) {
			this[callback]()
		},

		confirmDelete(item) {
			this.confirmationOpen = true
			this.confirmationConfig = {
				callback: 'deleteBadgeItem',
				title: 'Delete Badge',
				content: 'Are you sure you want to delete this badge?',
			}
			this.$refs.confirmation.id = item.id
		},

		deleteBadgeItem() {
			let id = this.$refs.confirmation.id
			let index = this.badges.findIndex(x => x.id === id)
			this.loaderShow()

			this.api.badges
				.destroy(id)
				.then(badges => {
					delete this.$refs.confirmation.id

					this.badges = badges

					this.closeModal()
				})
				.catch(error => {
					this.toast.error('Error deleting badges:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		fetchPages() {
			// this.loaderShow()
			this.api.pages
				.internal()
				.then(data => {
					// console.log(data)
					this.pageOptions = data.map(item => {
						return {
							value: item.id,
							label: '(' + item.path + ') ' + item.display_title,
						}
					})
				})
				.catch(error => {
					console.log(error)
					if (error?.response?.data?.message ?? undefined)
						this.toast.error('Error fetching pages:\n' + error.response.data.message)
				})
				.then(() => {
					// this.loaderHide()
				})
		},
	},
}
</script>
